@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600");
@import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
@font-face {
  font-family: "omgtransit";
  src: url("../fonts/omgfonts.eot");
  src: url("../fonts/omgfonts.eot?#iefix") format("embedded-opentype"), url("../fonts/omgfonts.woff") format("woff"), url("../fonts/omgfonts.ttf") format("truetype"), url("../fonts/omgfonts.svg#omgtransit") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
  font-family: "omgtransit";
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="omgicon-"] {
*/
[class*=icon-omg-] {
  font-family: "omgtransit";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.1;
  -webkit-font-smoothing: antialiased;
  vertical-align: middle;
}

.icon-omg-map:before {
  content: "\e600";
  font-size: 27px;
}

.icon-omg-list:before {
  content: "\e601";
  font-size: 27px;
}

.icon-omg-help:before {
  content: "\e602";
}

.icon-omg-filters:before {
  content: "\e603";
}

.icon-omg-feedback:before {
  content: "\e604";
}

.icon-omg-directions:before {
  content: "\e605";
}

.icon-omg-arrow-right:before {
  content: "\e606";
}

.icon-omg-arrow-left:before {
  content: "\e607";
}

.icon-omg-arrow-down:before {
  content: "\e608";
}

.icon-omg-alerts:before {
  content: "\e609";
}

.icon-omg-arrow-up:before {
  content: "\e60a";
}

body {
  margin: 0;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left);
  font-family: "Open Sans", Helvetica, sans-serif;
  background-color: #162d3d;
  color: #686868;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

header {
  display: inline-block;
  background-color: #162d3d;
  height: 50px;
  width: 100%;
}

main {
  background-color: white;
  width: 100%;
}

footer {
  display: inline-block;
  height: 60px;
  background-color: #162d3d;
  position: fixed;
  overflow: hidden;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 3;
  padding-bottom: env(safe-area-inset-bottom);
}
footer a {
  display: inline-block;
  padding: 5px 0px;
  color: white;
  font-size: 24px;
  text-decoration: none;
}
footer a.active {
  color: #ef502e;
}
footer a .text {
  font-weight: 100;
  font-size: 14px;
  display: block;
}

@media (max-width: 767px) {
  .content-width {
    width: 100%;
  }
  .heading, .stop-heading {
    font-size: 19px;
  }
  .arrival-bar,
  .availability-bar {
    font-size: 20px;
  }
  .description-text {
    font-size: 10.5px;
  }
  .static-content {
    font-size: 16px;
  }
  footer a {
    width: 100px;
  }
}
@media (min-width: 768px) {
  .content-width {
    width: 90%;
  }
  .heading, .stop-heading {
    font-size: 23px;
  }
  .arrival-bar,
  .availability-bar {
    font-size: 22px;
  }
  .description-text {
    font-size: 12px;
  }
  .static-content {
    font-size: 18px;
  }
  footer a {
    width: 80px;
  }
}
@media (min-width: 1024px) {
  .content-width {
    width: 80%;
  }
}
.header-container {
  height: 50px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.main-container {
  margin: 0 auto;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-weight: 300;
  height: calc(100vh - 110px + env(safe-area-inset-bottom) + env(safe-area-inset-top));
  overflow: scroll;
}

.map-container {
  width: 100%;
  height: calc(100vh - 110px + env(safe-area-inset-bottom) + env(safe-area-inset-top));
  margin: 0 auto;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-weight: 300;
  overflow: hidden;
}

#map-center {
  background-image: url("../images/crosshair.svg");
  position: absolute;
  height: 18px;
  width: 18px;
  background-size: 18px auto;
  background-repeat: no-repeat;
  background-origin: -9px -9px;
  top: -9px;
  left: -9px;
  margin: 0;
  padding: 0;
  opacity: 0.6;
  user-select: none;
}

#you-are-here {
  position: absolute;
  height: 18px;
  width: 18px;
  top: -10px;
  left: -10px;
  transform-origin: -10px -10px;
  margin: 0;
  padding: 0;
  background-color: royalblue;
  border: 1px solid white;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(65, 105, 225, 0.3);
  user-select: none;
}

#map-stop-preview {
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  background-color: white;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 60px;
  overflow: hidden;
  z-index: 2;
}
#map-stop-preview.visible {
  bottom: calc(60px + env(safe-area-inset-bottom));
  box-shadow: 0 0 7px 0 #777;
}

.map-location-button {
  cursor: pointer;
  position: relative;
  float: right;
  right: 10px;
  top: 10px;
  z-index: 10;
  width: 30px;
  height: 30px;
  padding: 12px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 2px 0 #777;
}
.map-location-button i {
  opacity: 0.9;
  color: #686868;
}

.map-pin {
  position: absolute;
  height: 36.5px;
  width: 24px;
  background-size: 24px auto;
  background-repeat: no-repeat;
  background-origin: 12px 36.5px;
  top: -36.5px;
  left: -12px;
  margin: 0;
  padding: 0;
}
.map-pin.clickable {
  cursor: pointer;
}
.map-pin.bus {
  background-image: url("../images/pin-bus.png");
}
.map-pin.bus.clickable:hover, .map-pin.bus.clickable.active {
  background-image: url("../images/pin-bus-hover.png");
}
.map-pin.train {
  background-image: url("../images/pin-train.png");
}
.map-pin.train.clickable:hover, .map-pin.train.clickable.active {
  background-image: url("../images/pin-train-hover.png");
}
.map-pin.bike {
  background-image: url("../images/pin-bike.png");
}
.map-pin.bike.clickable:hover, .map-pin.bike.clickable.active {
  background-image: url("../images/pin-bike-hover.png");
}

.header-logo {
  width: 116px;
  height: 30px;
}

.back-button {
  padding-left: 10px;
  color: white;
}

.more-menu {
  padding-right: 25px;
  color: white;
}

.stop-map-preview {
  width: 100%;
  height: 130px;
  margin: 0 auto;
}

.heading-bar {
  background-color: #efefef;
  color: #686868;
  height: 60px;
  text-align: left;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.heading, .stop-heading {
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  text-overflow: ellipsis;
  padding: 10px;
}

.stop-heading {
  font-weight: 300;
}

.favorite {
  padding: 10px;
  float: right;
}
.favorite a {
  text-decoration: none;
  color: #686868;
}
.favorite a.gold {
  color: #f2ae43;
}

.bottom-border {
  border-bottom: 1px solid #efefef;
}

.stop-preview {
  text-align: left;
  font-size: 18px;
  height: 60px;
  border-bottom: 1px solid #efefef;
  padding-left: 10px;
  display: flex;
  vertical-align: middle;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.stop-preview a {
  text-decoration: none;
  color: #686868;
}
.stop-preview:hover {
  background-color: #efefef;
}

.stop-preview:first-child {
  border-top: 1px solid #efefef;
}

.stop-info {
  width: 95%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.stop-title {
  font-size: 15px;
  text-transform: uppercase;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.arrivals,
.availability {
  width: 100%;
}

.stop-arrival-chips {
  line-height: 20px;
  overflow: hidden;
}

.arrival-chip {
  color: white;
  font-size: 12px;
  padding: 2px 4px;
  margin-left: 3px;
  height: 20px;
  border-radius: 2px;
}
.arrival-chip.p5 {
  background-color: #b94a48;
}
.arrival-chip.p10 {
  background-color: #f89406;
}
.arrival-chip.p20 {
  background-color: #c0c0c0;
}
.arrival-chip.pnd {
  background-color: black;
}
.arrival-chip.pnr {
  background-color: #9cc100;
}

.arrival-bar,
.availability-bar {
  display: inline-block;
  width: 100%;
  height: 67px;
  line-height: 20px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.arrival-bar.p5,
.availability-bar.p5 {
  background-color: #b94a48;
  color: white;
}
.arrival-bar.p10,
.availability-bar.p10 {
  background-color: #f89406;
  color: white;
}
.arrival-bar.p20,
.availability-bar.p20 {
  background-color: white;
  color: #686868;
}
.arrival-bar.pnd,
.availability-bar.pnd {
  background-color: black;
  color: white;
}
.arrival-bar.pnr,
.availability-bar.pnr {
  background-color: #9cc100;
  color: white;
}

.arrival-info,
.availability-info {
  display: inline-block;
  float: left;
  padding-left: 8px;
  vertical-align: middle;
  text-align: left;
  overflow: hidden;
}

.description-text {
  text-overflow: ellipsis;
}

.arrival-time {
  display: inline-block;
  float: right;
  font-style: italic;
  padding-right: 8px;
  vertical-align: middle;
}

.legend {
  line-height: 24px;
  margin: 10px 0px;
}

.scheduled {
  font-weight: 300;
}

.real-time {
  font-weight: 525;
}

.more a {
  text-decoration: none;
  color: #162d3d;
}
.more a:hover {
  color: #98c1e3;
}

.link-list {
  width: 220px;
  list-style: none;
  margin: auto;
  padding: 0;
  border: none;
}

.list-icon {
  display: inline-block;
  width: 40px;
  text-align: center;
  margin-right: 10px;
}

.static-content {
  padding: 10px;
  display: inline-block;
  max-width: 600px;
  line-height: 1.5em;
}

.team div {
  text-align: center;
  display: inline-block;
  width: 45%;
  margin-left: 3.2%;
  margin-bottom: 2em;
}
.team div img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 0.75em;
}

.awards {
  list-style: none;
  line-height: 1.5em;
  margin: auto;
  padding: 0;
  border: none;
}

.bold {
  font-weight: 600;
}

.centered-list {
  display: inline-block;
  margin: auto;
  padding: 0;
  border: none;
  text-align: left;
}

.status-container {
  width: 100%;
  background: #ef502e;
  color: white;
  position: fixed;
  z-index: 20;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 7px 0 #777;
}

.status-bar {
  margin: 0 auto;
  text-align: center;
  position: relative;
  margin: 0 auto;
  text-align: center;
  padding: 5px 0;
  display: flex;
  vertical-align: middle;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.status-bar a {
  color: white;
}
.status-bar a.status-message {
  text-decoration: underline;
}
.status-bar a:hover {
  color: #98c1e3;
}
.status-bar a:visited {
  color: white;
}
.status-bar .status-message {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: hidden;
  margin: 0 auto;
}
.status-bar .status-close {
  float: right;
  padding-right: 9px;
  width: 40px;
  font-size: 22px;
}

.message {
  padding: 30px 10px 0;
  line-height: 2;
}

.top-padded {
  padding-top: 5px;
}

#countdown-bar {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
}

progress[value] {
  height: 4px;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
}

progress[value]::-webkit-progress-bar {
  background-color: transparent;
  border-radius: 0 2px 2px 0;
}

progress[value]::-webkit-progress-value {
  border-radius: 0 2px 2px 0;
  background-color: #ef502e;
  transition: 1s all linear;
}

progress[value].clear::-webkit-progress-value {
  transition: none;
}

progress[value]::-moz-progress-bar {
  border-radius: 0 2px 2px 0;
  background-color: #ef502e;
}

.loading {
  margin: 50px auto;
}

.loading-animation {
  display: inline-block;
  width: 200px;
  margin-left: -30px;
}

.loading-text {
  font-weight: 700;
  font-size: 18px;
  color: #686868;
  text-transform: uppercase;
  line-height: 1.5em;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner {
  margin: 60px auto;
  text-indent: -9999em;
  width: 10em;
  height: 10em;
  position: relative;
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  border-radius: 50%;
  border-top: 3px solid #c0c0c0;
  border-right: 3px solid transparent;
  animation: spinner 0.7s linear infinite;
}
